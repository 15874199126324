<!--
 * @Date: 2022-03-25 11:00:03
 * @LastEditTime: 2022-03-25 16:17:01
 * @Description:
 * @FilePath: \BrainWapWebsite\pages\service\report.vue
-->
<template>
  <div style="background-color:#f5f5f5;">
    <div v-if="!noPatient" class="has-patient">
      <patientSelect @onConfirm="patientConfirm" />
      <load-more :data-source="dataList" :total="total" :loading="loading" @load-more="onLoad">
        <report-item :data="dataList" @showQrDialog="showQrDialog" />
      </load-more>
    </div>
    <!--如果当前登录的用户下没有就诊人，显示添加就诊人按钮-->
    <div v-if="noPatient" class="add-patient">
      <img width="125" src="../img/add-patient_1x.png" alt="添加就诊人">
      <p class="add-patient-text">
        根据就诊人查询报告，请先添加就诊人
      </p>
      <van-button icon="add-o" round size="normal" type="info" native-type="submit" class="add-patient-btn" @click="addPatient">
        添加就诊人
      </van-button>
    </div>
    <!--    二维码弹框-->
    <van-dialog v-model="visible" class="qrcode-dialog" title="" :show-confirm-button="false" confirm-button-color="#333" confirm-button-text="关闭" :close-on-click-overlay="true">
      <div class="qrcode-box">
        <div id="qrcode" ref="qrCodeUrl" class="qrcode" />
        <div class="qrCode-dialog-tips-box">
          <span class="qrCode-dialog-tips">二维码将在15分钟之后失效</span>
          <span class="qrCode-dialog-tips">失效后请重新进入列表刷新</span>
          <span class="qrCode-dialog-tips">长按或者截屏保存二维码</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
  import QRCode from 'qrcodejs2'
  import loadMore from '@/assets/js/mixin/load-more'
  import reportItem from '@/components/reportItem/reportItem'
  import { patientModel } from '@/api/patient'
  import { userModel } from '@/api/user'
  export default {
    name:'ImageReport',
    components: { reportItem },
    mixins:[loadMore],
    middleware: 'auth',
    data() {
      return{
        patientId: '',
        noPatient: false,
        dataList: [],
        visible: false,
        qrcode: {},
        pollingTimer: {}
      }
    },
    beforeDestroy() {
      clearInterval(this.pollingTimer)
    },
    mounted() {
      this.getPatientList()
      this.pollingData()
    },
    methods:{
      // 判断有没有就诊人
      getPatientList(){
        patientModel.getPatientList().then(res=>{
          let patientList =res.data ? res.data.patLists : []
          this.noPatient = patientList.length===0
        })
      },
      // 选择就诊人
      patientConfirm(value){
        this.patientId = value.id || ''
        this.page = 1
        this.dataList=[]
        this.getList()
      },
      // 获取列表数据
      getList(){
        let params = {
          patId: this.patientId,
          page: this.page,
          pageSize: this.pageSize
        }
        // console.log('影像列表页-传给接口的params:', params)
        userModel.getFTImage(params).then(res=>{
          this.dataList.push(...(res.data || []))
          this.total = res.count || 0
          this.loading = false
          // console.log('this.dataList飞图影像列表', this.dataList)
        })
      },
      // 轮询
      pollingData() {
        // 15分钟执行一次
        this.pollingTimer = setInterval(() => {
          this.getList()
        }, 900000)
      },

      // 创建二维码弹框
      creatQrCode(url) {
        // 二维码宽高自适应
        let long =document.documentElement.clientWidth ? document.documentElement.clientWidth * (200 / 375) : 200
        this.$nextTick(() => {
          this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: url, // 需要转换为二维码的内容
            width: long,
            height: long,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        })
      },
      onLoad() {
        this.loading=true
        this.page+=1
        this.getList()
      },
      // 显示二维码弹框
      showQrDialog(url) {
        const codeHtml = document.getElementById('qrcode')
        if(codeHtml) {
          codeHtml.innerHTML = ''
        } // 避免重复生成二维码
        this.visible = true
        this.creatQrCode(url)
      },
      // 跳转到添加就诊人页面
      addPatient() {
        this.$router.push('/patient')
      }
    }
  }
</script>
<style lang='less' scoped>
@import '../../../assets/styles/variable.less';
.add-patient{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 110px 32px 0;
  .add-patient-text{
    color: @dark-font-color;
    font-size: 28px;
    margin-top: 32px;
    line-height: 40px;
  }
  .add-patient-btn{
    width: 100%;
    margin-top: 72px;
    height: 80px;
    .van-button__text{
      font-size: 30px;
      line-height: 80px;
    }
  }
}
/deep/ .van-dropdown-menu__bar {
  background-color: @theme-color;
}

/deep/.van-dropdown-menu__title, /deep/.van-dropdown-menu__title--active {
  color: #ffffff !important;
}
.qrcode-dialog{
  background: url("../../../assets/img/dialog-bg.png") no-repeat;
  background-size: cover;
  .qrcode-box{
    margin: 40px 48px;
    padding: 32px 40px;
    border-radius: 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .qrCode-dialog-tips-box{
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      .qrCode-dialog-tips{
        line-height: 40px;
        color: #6A6D78;
        font-size: 24px;
      }
    }
    .qrcode {
      background-color: #fff;
      margin-bottom: 20px;
      padding: 6px;
      box-sizing: border-box;
    }
  }
}

</style>
