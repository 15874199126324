<template>
  <div>
    <div v-for="(item,index) in data" :key="index" class="card-item">
      <div class="cell">
        <div class="cell_title">
          检查类型：
        </div>
        <div class="cell_value">
          {{ item.modality }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          就诊人：
        </div>
        <div class="cell_value">
          {{ item.patName }} &nbsp;&nbsp; {{ item.patGender }} &nbsp;&nbsp;{{ item.patAge +'岁' }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          检查项目：
        </div>
        <div class="cell_value">
          {{ item.checkItem }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          检查时间：
        </div>
        <div class="cell_value">
          {{ item.studyTime }}
        </div>
      </div>
      <div class="cell border_top">
        <div class="cell_value">
          <div class="cancel_btn" @click="openUrl(item.url)">
            查看详情
          </div>
          <div class="cancel_btn share_btn" @click="showDialog(item.url)">
            分享
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name:'ReportItem',
    props:{
      data: {
        type: Array,
        default: () => ([])
      }
    },
    methods:{
      openUrl(url){
        // console.log('飞图url', url)
        this.$router.push({
          path:'/ft-report-detail',
          query:{
            url:url
          }
        })
      },
      showDialog(url) {
        this.$emit('showQrDialog', url)
      }
    }
  }
</script>
<style scoped  lang='less'>
@import '../../assets/styles/variable.less';
.card-item{
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 32px;
  padding-bottom:20px;
  .docbox{
    display: flex;
    padding: 32px;
    justify-content: space-between;
    box-sizing: border-box;
  }
    .photo{
      width: 92px;
      flex: 0 0 92px;
      height: 92px;
      margin-right: 16px;
    }
    .mid{
      //width 410px
      flex: 1;
      line-height: 40px;
      margin-top: -10px;
    }

  .cell{
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 32px;
    overflow: hidden;
    color: @dark-font-color;
    font-size: 28px;
    line-height: 40px;
  }

  .cell_title, .cell_value{
    -webkit-box-flex: 1;
    flex: 1;
  }

  .cell_value {
    overflow: hidden;
    text-align: right;
  }
    .cancel_btn {
      width: 140px;
      color: #3377FE;
      text-align: center;
      border-radius: 24px;
      border: 1px solid #3377FE;
      padding: 4px 5px;
      font-size: 24px;
      float: right;
      cursor: pointer;
    }

    .share_btn{
      margin-right: 10px;
      color: #333333;
      border: 1px solid #D3D5E2;
    }

  .border_top{
    border-top: 1px solid #eee;
    padding-top: 20px;
    margin-top: 20px;
  }

.red-color{
  color: #FF2C23;
}

  .mt10 {
    margin-top: 10px;
  }

  .ml10 {
    margin-left: 10px;
  }

  .modal-tel{
    display: block;
    width: 100%;
    padding: 32px 0;
    text-align: center;
    font-size: @font-size-28;
    color: @theme-color;
  }

}
</style>
